import React, { useEffect, useState } from 'react';
import './Row.css';
import axios from '../axios';
import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const base_url = "https://image.tmdb.org/t/p/original/";

function Row({ title, fetchUrl, isLargeRow }) {
    const [movies, setMovies] = useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [loaderMovies, setLoaderMovies] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoaderMovies(true); // Start loading
                const request = await axios.get(fetchUrl);
                setMovies(request.data.results);
            } catch (error) {
                console.error("Failed to fetch movies", error);
            } finally {
                setLoaderMovies(false); // Stop loading
            }
        }
        fetchData();
    }, [fetchUrl]);

    const opts = {
        height: "390",
        width: "100%",
        playerVars: {
            autoplay: 1,
        },
    };

    const handleClick = async (movie) => {
        if (trailerUrl) {
            setTrailerUrl("");
        } else {
            try {
                const movieName = movie?.name || movie?.title || movie?.original_title || "";
                const url = await movieTrailer(movieName);

                if (url) {
                    const urlParams = new URLSearchParams(new URL(url).search);
                    setTrailerUrl(urlParams.get('v'));
                } else {
                    console.log('No trailer found for this movie.');
                }
            } catch (error) {
                console.error('Error finding trailer:', error);
            }
        }
    };

    return (
        <div className='row'>
            <h2>{loaderMovies ? <Skeleton width={200} duration={0.5} /> : title}</h2>
            <div className='row_posters'>
                {loaderMovies ? (
                    Array(20).fill().map((_, index) => (
                        <Skeleton 
                            key={index} 
                            width={isLargeRow ? 200 : 150} 
                            height={isLargeRow ? 300 : 200} 
                            className={`row_poster ${isLargeRow && "row_posterLarge"}`}
                            duration={0.5} // Faster animation
                        />
                    ))
                ) : (
                    movies.map(movie => (
                        <img 
                            key={movie.id}
                            onClick={() => handleClick(movie)}
                            className={`row_poster ${isLargeRow && "row_posterLarge"}`}
                            src={`${base_url}${isLargeRow ? movie.poster_path : movie.backdrop_path}`}
                            alt={movie.name}
                        />
                    ))
                )}
            </div>
            {trailerUrl && (
                <div className='row_trailer'>
                    <YouTube videoId={trailerUrl} opts={opts} />
                    <button className='row_trailerClose' onClick={() => setTrailerUrl("")}>
                        X
                    </button>
                </div>
            )}
        </div>
    );
}

export default Row;
