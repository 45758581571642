import React, { useEffect, useState } from 'react';
import './Banner.css';
import axios from '../axios';
import requests from '../request';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Banner = () => {
    const [movie, setMovie] = useState([]);
    const [loaderMovie, setLoaderMovie] = useState(false);

    async function fetchData(){
        try {
            setLoaderMovie(true); // Start loading
            const request = await axios.get(requests.fetchNetflixOriginals);
            setMovie(
                request.data.results[
                    Math.floor(Math.random() * request.data.results.length)
                ]
            );
        } catch (error) {
            console.error("Failed to fetch data", error);
        } finally {
            setLoaderMovie(false); // Stop loading, even if there's an error
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    function truncate(str, n){
        return str?.length > n ? str.substr(0, n-1)+ "..." : str;
    }

    return (
        <header className='banner' style={{backgroundSize:"cover", backgroundImage:`url(https://image.tmdb.org/t/p/original/${movie?.backdrop_path})`, backgroundPosition:"center-center"}}>
            <div className='banner_contents'>
                <h1 className='banner_title'>
                    {loaderMovie ? <Skeleton width={300} height={40} /> : movie?.title || movie?.name || movie?.original_name}
                </h1>
                <div className='banner_buttons'>
                    {loaderMovie ? (
                        <Skeleton width={100} height={40} />
                    ) : (
                        <>
                            <button className='banner_button'>Play</button>
                            <button className='banner_button'>My List</button>
                        </>
                    )}
                </div>
                <h1 className='banner_description'>
                    {loaderMovie ? <Skeleton count={3} /> : truncate(movie?.overview, 150)}
                </h1>
            </div>
            <div className='banner_fadeBottom'/>
        </header>
    );
};

export default Banner;
